<template>
  <div v-if="items.length">
    <div class="card border-info">
      <div class="card-header p-2 bg-info text-light">เลขอั้น {{ bet.text }}</div>
      <div class="card-body p-0">
        <table class="table table-sm mb-0 table-hover">
          <tbody>
            <tr v-for="item in items" :class="item.class">
              <td width="50%" class="text-center"><span class="badge badge-primary">{{item.number}}</span></td>
              <td class="text-center">{{item.text}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'LimitNumbers',
  props: ['bet', 'data'],
  computed: {
    items() {
      const closeNumbers = this.data.round.closeNumbers[this.bet.code]
      const payHalfNumbers = this.data.round.payHalfNumbers[this.bet.code]
      const numbers = _.uniq(closeNumbers.concat(payHalfNumbers))

      return _.sortBy(numbers, (n)=>{
        return parseInt(n)
      })
      .reduce((items, number)=>{
        if(closeNumbers.includes(number)){
          items.push({
            number: number,
            text: 'ปิดรับ',
            class: 'alert-danger'
          })
        }else{
          items.push({
            number: number,
            text: 'จ่ายครึ่ง',
            class: 'alert-warning'
          })
        }
        return items
      }, [])
    }
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-weight: normal;
  font-size: 90%;
}
.table {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        line-height: 1;
      }
    }
  }
}
</style>
