<template>
  <div class="set-numbers">

    <div v-if="threeNumbers.length || twoNumbers.length || oneNumbers.length" class="row no-gutters mx-n1">
      <div v-if="threeNumbers.length" class="col-md-4 px-1">
        <div class="card border-info mb-0">
          <div class="card-header p-1 bg-info text-light">3 ตัว</div>
          <div class="card-body p-0">
            <table class="table table-sm table-bordered mb-0 table-info table-hover">
              <thead>
                <tr>
                  <th width="30%" class="text-center">เลข</th>
                  <th width="35%" class="text-center">บน</th>
                  <th width="35%" class="text-center">โต๊ด</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in threeNumbers" :key="item.number">
                  <td class="text-center">
                    <span class="text-primary">{{ item.number }}</span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.top === 'c'" class="badge badge-danger">ปิดรับ</span>
                    <span v-else-if="item.top === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
                    <span v-else-if="item.top === 'f'" class="badge badge-success">จ่ายเต็ม</span>
                    <span v-else class="text-danger">{{ item.top }}</span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.tode === 'c'" class="badge badge-danger">ปิดรับ</span>
                    <span v-else-if="item.tode === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
                    <span v-else-if="item.tode === 'f'" class="badge badge-success">จ่ายเต็ม</span>
                    <span v-else class="text-danger">{{ item.tode }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="twoNumbers.length" class="col-md-4 px-1">
        <div class="card border-info mb-0">
          <div class="card-header p-1 bg-info text-light">2 ตัว</div>
          <div class="card-body p-0">
            <table class="table table-sm table-bordered mb-0 table-info table-hover">
              <thead>
                <tr>
                  <th width="30%" class="text-center">เลข</th>
                  <th width="35%" class="text-center">บน</th>
                  <th width="35%" class="text-center">ล่าง</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in twoNumbers" :key="item.number">
                  <td class="text-center">
                    <span class="text-primary">{{ item.number }}</span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.top === 'c'" class="badge badge-danger">ปิดรับ</span>
                    <span v-else-if="item.top === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
                    <span v-else-if="item.top === 'f'" class="badge badge-success">จ่ายเต็ม</span>
                    <span v-else class="text-danger">{{ item.top }}</span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.bot === 'c'" class="badge badge-danger">ปิดรับ</span>
                    <span v-else-if="item.bot === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
                    <span v-else-if="item.bot === 'f'" class="badge badge-success">จ่ายเต็ม</span>
                    <span v-else class="text-danger">{{ item.bot }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="oneNumbers.length" class="col-md-4 px-1">
        <div class="card border-info mb-0">
          <div class="card-header p-1 bg-info text-light">เลขวิ่ง</div>
          <div class="card-body p-0">
            <table class="table table-sm table-bordered mb-0 table-info table-hover">
              <thead>
                <tr>
                  <th width="30%" class="text-center">เลข</th>
                  <th width="35%" class="text-center">บน</th>
                  <th width="35%" class="text-center">ล่าง</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in oneNumbers" :key="item.number">
                  <td class="text-center">
                    <span class="text-primary">{{ item.number }}</span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.top === 'c'" class="badge badge-danger">ปิดรับ</span>
                    <span v-else-if="item.top === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
                    <span v-else-if="item.top === 'f'" class="badge badge-success">จ่ายเต็ม</span>
                    <span v-else class="text-danger">{{ item.top }}</span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.bot === 'c'" class="badge badge-danger">ปิดรับ</span>
                    <span v-else-if="item.bot === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
                    <span v-else-if="item.bot === 'f'" class="badge badge-success">จ่ายเต็ม</span>
                    <span v-else class="text-danger">{{ item.bot }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-warning mb-0 p-2">
      ไม่มีเลขอั้น
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'RoundSetNumbers',
  props: ['data'],
  computed: {
    threeNumbers() {
      const threeNumberTop = this.data?.threeNumberTop || {}
      const threeNumberTode = this.data?.threeNumberTode || {}

      const topKeys = Object.keys(threeNumberTop)
      const todeKeys = Object.keys(threeNumberTode)
      const keys = _.sortBy(_.uniq(topKeys.concat(todeKeys)), (k)=>{
        return parseInt(k)
      })

      return keys.map((number) => {
        return {
          number: number,
          top: threeNumberTop?.[number] || 'f',
          tode: threeNumberTode?.[number] || 'f'
        }
      })
    },
    twoNumbers() {
      const twoNumberTop = this.data?.twoNumberTop || {}
      const twoNumberBottom = this.data?.twoNumberBottom || {}

      const topKeys = Object.keys(twoNumberTop)
      const botKeys = Object.keys(twoNumberBottom)
      const keys = _.sortBy(_.uniq(topKeys.concat(botKeys)), (k)=>{
        return parseInt(k)
      })

      return keys.map((number) => {
        return {
          number: number,
          top: twoNumberTop?.[number] || 'f',
          bot: twoNumberBottom?.[number] || 'f'
        }
      })
    },
    oneNumbers() {
      const runTop = this.data?.runTop || {}
      const runBottom = this.data?.runBottom || {}

      const topKeys = Object.keys(runTop)
      const botKeys = Object.keys(runBottom)
      const keys = _.sortBy(_.uniq(topKeys.concat(botKeys)), (k)=>{
        return parseInt(k)
      })

      return keys.map((number) => {
        return {
          number: number,
          top: runTop?.[number] || 'f',
          bot: runBottom?.[number] || 'f'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.set-numbers {
  .card {
    .badge {
      font-weight: normal;
    }

    .table {
      background-color: #FFFFFF;
      border-left: 0;
      border-right: 0;

      tr {
        th:first-child, td:first-child {
          border-left: 0;
        }
        th:last-child, td:last-child {
          border-right: 0;
        }
        th {
          font-weight: normal;
        }
        td {
          vertical-align: middle;
        }
      }

      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
